/**
 * 设置 token
 * @param {*} token
 * @returns
 */
export const setToken = (token) => sessionStorage.setItem("token", token);
/**
 * 获取 token
 * @returns
 */
export const getToken = () => sessionStorage.getItem("token");
/**
 * 移除 token
 * @returns
 */
export const removeToken = () => sessionStorage.removeItem("token");
/**
 * 判断是否登录
 * @returns
 */
export const isLogined = () => (getToken() ? true : false);
