<template>
  <div id="app">
    <router-view class="main"> </router-view>
    <!--  :fixed="false" 清除浮动 -->
    <van-tabbar v-show="isShowTabBar" active-color="pink" :fixed="false" route>
      <van-tabbar-item icon="home-o" :to="{ name: 'Home' }"
        >首页</van-tabbar-item
      >
    <!--  <van-tabbar-item icon="fire-o" :to="{ name: 'List' }"
        >热卖</van-tabbar-item
      >
      <van-tabbar-item
        icon="shopping-cart-o"
        :to="{ name: 'Cart' }"
      >
        购物车</van-tabbar-item
      > -->
      <van-tabbar-item icon="user-o" :to="{ name: 'User' }"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isLogined } from "./utils/auth";
export default {
  data() {
    return {
      isShowTabBar: true,
    };
  },
/*  computed: {
    ...mapGetters(["cartCount"]),
  }, */
  created() {
    if (isLogined()) {
      // dispatch 派发一个action 获取购物车信息
    }
  },
  watch: {
    // 监听路由变化
    $route(val) {
      // 判断当前跳转到页面是否需要隐藏底部导航
      if (val.meta.hideTabBar) {
        this.isShowTabBar = false; // 是就隐藏
      } else {
        this.isShowTabBar = true; // 不是就显示
      }
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
html,
body,
#app {
  width: 100vw;
  height: 100vh;
}
#app {
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1;
  overflow: auto;
}
img {
  max-width: 100%;
}
</style>
