import Vue from "vue";
import Vuex from "vuex"; // 引入vuex
import Vant from "vant"; // 引入vant
import "vant/lib/index.css";
import App from "./App.vue";
import router from "./router";
import { serverUrl } from "./utils/request"; //  导入基础路径
import { loadCartsAPI } from "./service/carts";

Vue.use(Vant); // 使用vant
Vue.use(Vuex); // 使用vuex
Vue.config.productionTip = false;

// 全局过滤器
Vue.filter("dalImg", (url) => {
  if (url) {
    if (url.startsWith("http")) {
      return url;
    }
    return serverUrl + url;
  } else {
    return "https://img1.baidu.com/it/u=1985847945,925223576&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281";
  }
});

const store = new Vuex.Store({
  state: {
    carts: [],
  },
  actions: {
   /* async loadCarts({ commit }) {
      const res = await loadCartsAPI();
      commit("loadCartsEnd", res.data);
    }, */
  },
  mutations: {
    loadCartsEnd(state, payload) {
      state.carts = payload.filter((item) => item.product); // 赋值，并过滤存在的商品
    },
    // 重置购物车信息
    resetCarts(state) {
      state.carts = [];
    },
  },
  // 相当于计算属性
  getters: {
    // 购物车中数据的数量
    cartCount(state) {
      return state.carts.length;
    },
  },
});

/**
 * @param fmt
 * @returns {*}
 * @constructor 日期的格式化
 */
Date.prototype.Format = function (fmt) {
	var o = {
		"M+": this.getMonth() + 1, // 月份
		"d+": this.getDate(), // 日
		"h+": this.getHours(), // 小时
		"m+": this.getMinutes(), // 分
		"s+": this.getSeconds(), // 秒
		"q+": Math.floor((this.getMonth() + 3) / 3), // 季度
		"S": this.getMilliseconds() // 毫秒
	};
	if (/(y+)/.test(fmt))
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
}


new Vue({
  router, // 使用路由插件
  store,
  render: (h) => h(App),
}).$mount("#app");
