import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import { isLogined } from "../utils/auth";

Vue.use(VueRouter);

// routes 路由表
const routes = [
  {
    path: "/",
    name: "Home",
	meta: {
	  needLogin: true,
	},
    component: HomeView,
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("../views/Login.vue"),
    meta: {
      hideTabBar: true, //  是否隐藏底部导航
    },
  },
  {
    name: "User",
    path: "/user",
    meta: {
      needLogin: true,
    },
    component: () => import("../views/User.vue"),
  },
  {
    name: "Scores",
    path: "/scores",
    component: () => import("../views/User/Scores.vue"),
  },
  {
    name: "Orders",
    path: "/orders",
	meta: {
	  needLogin: true,
	},
    component: () => import("../views/User/Orders.vue"),
  },
  {
    name: "Info",
    path: "/info",
    component: () => import("../views/User/Info.vue"),
  },
  {
    name: "PWD",
    path: "/pwd",
    component: () => import("../views/User/PWD.vue"),
  },
  {
    name: "List",
    path: "/list",
	meta: {
	  needLogin: true,
	},
    component: () => import("../views/List.vue"),
  },
  {
    name: "Detail",
    path: "/list/:id/:name?", // params 占位符可以写多个 ? 表示可选
    meta: {
      hideTabBar: true, //  是否隐藏底部导航
	  needLogin: true,
    },
    component: () => import("../views/Detail.vue"),
  },
  {
    name: "Cart",
    path: "/cart",
    meta: {
      needLogin: true, // 是否需要登录
      hideTabBar: true, //  是否隐藏底部导航
    },
    component: () => import("../views/Cart.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "hash", // hash 路由
  // mode: "history", // 浏览器历史记录路由
  // 常见的路由模式，hash 和 history
  // `hash`路由，hash模式不需要在发布的时候做特殊配置，跳转路径的时候带#号
  // `history`浏览器历史记录路由，history模式需要在发布的时候做特殊的配置，跳转路径的时候不带#号
});

// 路由前置守卫
router.beforeEach((to, from, next) => {
  if (to.meta.needLogin) {
    // 判断是否登录
    if (isLogined()) {
      next();
    } else {
      next({
        name: "Login",
      });
    }
  } else {
    next();
  }
});

export default router;
