import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Notify } from "vant";
import { getToken } from "./auth";
import { setToken } from "./auth";
export const serverUrl = "http://www.financecw123.top/a/v1";
import router from "../router/index.js";
const instance = axios.create({
  baseURL: serverUrl,
  timeout: 5000,
});

// Add a request interceptor
// 全局请求拦截 发起网络请求之前执行
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    NProgress.start();
    // token的添加方式不是固定的，每一个接口文档都会有说明
	config.headers= {
		'content-type': 'application/json',
		'Pf': 'Admin',
		'Authorization':getToken()
	}
    //config.headers.token = getToken(); // 在请求头中设置token
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
// 全局响应拦截 网络请求完成之后执行
instance.interceptors.response.use(
  function (response) {
	console.log(response); //  打印 response
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    NProgress.done();
	if(response.data.code==40002){
		setToken('');
		router.replace('/')
	}
    return response.data;
  },
  function (error) {
     console.log(error); //  打印 error
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // 重复注册提示报错，提升用户体验
    if (error.response?.data) {
      Notify({
        type: "danger",
        message:
          error.response.status +
          "|" +
          (error.response.data.message
            ? error.response.data.message
            : error.response.data.info),
      });
    }
    NProgress.done();
    return Promise.reject(error);
  }
);

/**
 * 发起一个get请求
 * @param {*} url
 * @param {*} params
 * @returns
 */
export const get = (url, params) => instance.get(url, { params });

/**
 * 发起一个post请求
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const post = (url, data) => instance.post(url, data);

/**
 * 发起一个put请求
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const put = (url, data) => instance.put(url, data);

/**
 * 发起一个delete请求
 * @param {*} url
 * @returns
 */
export const del = (url) => instance.delete(url);
