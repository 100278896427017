<template>
  <div class="home">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in banners" :key="item.id">
        <img :src="item.coverImage" alt="" class="s-img" />
      </van-swipe-item>
    </van-swipe>
    <van-grid :column-num="2">
      <van-grid-item
        v-for="item in categories"
        :key="item.id"
      >
	    <van-image :width="60" :src="item.coverImage" />
		<div style="margin-top: 10px;">{{item.name}}</div>
	  </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      banners: [{
		  id:1,
		  coverImage:require("@/assets/banners/banner1.jpg")
	  },{
		  id:2,
		  coverImage:require("@/assets/banners/banner2.jpg")
	  },{
		  id:3,
		  coverImage:require("@/assets/banners/banner3.jpg")
	  }],
      categories: [{
		  coverImage:require("@/assets/category/fruit.jpg"),
		  name:'新鲜水果'
	  },{
		  coverImage:require("@/assets/category/water.jpg"),
		  name:'海鲜水产'
	  },{
		  coverImage:require("@/assets/category/egg.jpg"),
		  name:'精选肉类'
	  },{
		  coverImage:require("@/assets/category/cake.jpg"),
		  name:'冷饮冻食'
	  },{
		  coverImage:require("@/assets/category/vegtables.jpg"),
		  name:'时令蔬菜'
	  },{
		  coverImage:require("@/assets/category/chicken.jpg"),
		  name:'肉禽蛋品'
	  }],
      products: [],
    };
  },
  components: {},
  created() {
  },
  methods: {
   
  },
};
</script>

<style scoped>
.my-swipe {
  width: 100vw;
  height: 50vw;
}
.s-img {
  width: 100%;
}

</style>
